exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-turniere-index-js": () => import("./../../../src/pages/turniere/index.js" /* webpackChunkName: "component---src-pages-turniere-index-js" */),
  "component---src-pages-users-logout-js": () => import("./../../../src/pages/users/logout.js" /* webpackChunkName: "component---src-pages-users-logout-js" */),
  "component---src-pages-users-passwordreset-js": () => import("./../../../src/pages/users/passwordreset.js" /* webpackChunkName: "component---src-pages-users-passwordreset-js" */),
  "component---src-pages-users-profile-js": () => import("./../../../src/pages/users/profile.js" /* webpackChunkName: "component---src-pages-users-profile-js" */),
  "component---src-pages-users-signin-js": () => import("./../../../src/pages/users/signin.js" /* webpackChunkName: "component---src-pages-users-signin-js" */),
  "component---src-pages-users-signup-js": () => import("./../../../src/pages/users/signup.js" /* webpackChunkName: "component---src-pages-users-signup-js" */),
  "component---src-pages-was-ist-ultimate-js": () => import("./../../../src/pages/was_ist_ultimate.js" /* webpackChunkName: "component---src-pages-was-ist-ultimate-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-report-js": () => import("./../../../src/templates/report.js" /* webpackChunkName: "component---src-templates-report-js" */)
}

